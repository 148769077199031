export enum Dictionaries {
  AudioTypes = 'AudioTypes',
  Languages = 'Languages',
  MovieVersionTypes = 'MovieVersionTypes',
  AgeRatings = 'AgeRatings',
  Topics = 'Topics',
  MovieTypes = 'MovieTypes',
  MovieDiscriptors = 'MovieDiscriptors',
  Countries = 'Countries',
  CastTypes = 'CastTypes',
  Roles = 'Roles',
  VideoTypes = 'VideoTypes',
  SalesTax = 'SalesTax',
  CountriesMovie = 'CountriesMovie',
  TerminalTypes = 'TerminalTypes',
  KioskLayoutTypes = 'KioskLayoutTypes',
  Distributors = 'Distributors',
  'Mandators' = 'Mandators'
}
