import { Directive, inject, Input } from '@angular/core';
import { ImageTypes, ImageUrlService } from '@cinetixx/cinetixx-ui';

@Directive({
  selector: 'img[appPreloadImage]',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src'
  }
})
export class PreloadImageDirective {

  @Input() src: string;
  @Input() imgId: string;
  @Input() imgFallbackWidth: number;
  @Input() imgFallbackHeight: number;
  @Input() type: ImageTypes;

  private readonly _defaultPosterUrl = '/assets/images/no-movie-poster.svg';
  private readonly _default = 'default';

  private readonly _imageUrlService = inject(ImageUrlService);

  public updateUrl(): void {
    if (this.type === ImageTypes.POSTER) {
      if (this.imgId) {
        this.src = this.generateUrl(this.type, this.imgId, 211, 300)
      } else {
        this.src = this._defaultPosterUrl;
      }
    } else {
      if (this.imgId) {
        this.src = this.generateUrl(this.type, this.imgId, this.imgFallbackWidth, this.imgFallbackHeight);
      } else {
        this.src = this.generateUrl(this.type, this._default, this.imgFallbackWidth, this.imgFallbackHeight);
      }
    }
  }

  private generateUrl(type: ImageTypes, imageId: string, width: number, height: number): string {
    switch (type) {
      case ImageTypes.POSTER:
        return this._imageUrlService.generatePosterUrl(imageId, width, height);
      case ImageTypes.SCENE:
        return this._imageUrlService.generateSceneUrl(imageId, width, height);
      case ImageTypes.LOGO:
        return this._imageUrlService.generateLogoUrl(imageId, width, height);
      case ImageTypes.AVATAR:
        return this._imageUrlService.generateAvatarUrl(imageId, width, height);
      default:
        return imageId;
    }
  }
}
