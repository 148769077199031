import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { StaticDependenciesService } from './StaticDependenciesService';
import { HubConnectionState } from '@microsoft/signalr';
import { ISignalRError } from '../interfaces';
import { inject } from '@angular/core';
import { AuthService } from '../../auth/core/services/AuthService';
import { IdentityService } from './IdentityService';

export abstract class AbstractSignalRService {

  protected readonly wsUrl = environment.wsUrl;

  private _connection: signalR.HubConnection;
  private _wsMethods: { name: string; method: (...args: any[]) => void; }[] = [];
  private _reconnectWithNewTokenAttempts = 0;
  private readonly _authService = inject(AuthService);
  private readonly _identityService = inject(IdentityService);

  protected constructor(
    protected readonly hubUrl: string,
    protected readonly initMethod = 'initializeAsync'
  ) {}

  public start(): void {
    if (this._connection?.state !== HubConnectionState.Connected) {
      this._reconnectWithNewTokenAttempts = 0;

      this._connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(`${ this.wsUrl }${ this.hubUrl }`)
        .withAutomaticReconnect()
        .build();

      this.handleReconnect();
      this._connection.start().then(this.invoke.bind(this));
      this.createMethod('disconnected', (arg: ISignalRError) => {
        if (arg) {
          if (arg.errorCode === 401 && this._reconnectWithNewTokenAttempts < 6) {
            this._authService.refreshToken().subscribe({
              next: res => {
                this._identityService.identity = {
                  ...this._identityService.identity,
                  accessToken: res.accessToken
                };

                this.reconnect();
                this._reconnectWithNewTokenAttempts++;
              },
              error: this._authService.logout.bind(this)
            });
          } else {
            this._authService.logout();
          }
        }
      });
    }
  }

  public createMethod(name: string, method: (...args: any[]) => void): void {
    this._connection.on(name, method);

    if (!this._wsMethods.find(x => x.name === name)) {
      this._wsMethods.push({ name, method });
    }
  }

  public stop(): void {
    this._connection.stop();
    this._wsMethods = [];
  }

  private invoke(): void {
    this._connection.invoke(this.initMethod, StaticDependenciesService.tokenService.token);
  }

  private reconnect(): void {
    this.invoke();
    this._wsMethods.forEach(x => this._connection.on(x.name, x.method));
  }

  private handleReconnect(): void {
    this._connection.onreconnected(this.reconnect.bind(this));
    this._connection.onclose((error) => {
      if (error) {
        console.log(`SignalR connection closed with error: ${ error }`);
        this.start();
      }
    });
  }
}
